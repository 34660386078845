<div class="holder">
  <div class="text-container">
    <h2>{{ environment.siteList === 'prod' ? 'Tjänsteportalen' : 'Tjänsteportalen testmiljö' }}</h2>
    <p>Välkommen till Tjänsteportalen{{ environment.siteList === 'test' ? ' testmiljö' : '' }}, portal för samtliga
      system som är framtagna via Linaf.</p>
    <p [style.margin-bottom]="'16px'">Logga in med BankID för att komma igång.</p>
    <spb-bankid (accessToken)="configService.setToken($event).subscribe()"
                [bankIdUrl]="bankIdUrl"
                [domain]="domain"
                mode="login"
    ></spb-bankid>
  </div>
</div>