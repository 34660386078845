import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, InjectionToken} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {ConfigService} from './services/config.service'
import {environment} from '../environments/environment'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {provideAnimations} from '@angular/platform-browser/animations'

import {TStageName} from './common/types'
import {provideRouter, withHashLocation, withInMemoryScrolling} from '@angular/router'
import {routes} from './app.routes'

/**
 * Bla bla taking the long route to inject
 * the environment to get hold of the "test" | 'prod'
 * setting
 */
export interface IEnvironment {
  siteList: TStageName
}

export const ENVIRONMENT =
  new InjectionToken<IEnvironment>('environment')

export const LOCAL_STORAGE = new InjectionToken<Storage>('localStorage')

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    // Used by config service and has to be provided in "root"
    {provide: ENVIRONMENT, useValue: environment},
    importProvidersFrom(BrowserModule, ThemeModule),
    provideRouter(routes, withHashLocation(), withInMemoryScrolling({anchorScrolling: 'enabled'})),
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    provideAnimations()
  ]
}